define("ember-best-language/services/best-language", ["exports", "@ember/application", "@ember/service"], function (_exports, _application, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let BestLanguage = _exports.default = (_dec = (0, _service.inject)('best-language/fastboot'), _dec2 = (0, _service.inject)('best-language/browser'), (_class = class BestLanguage extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "fastbootAdapter", _descriptor, this);
      _initializerDefineProperty(this, "browserAdapter", _descriptor2, this);
    }
    get fastboot() {
      return (0, _application.getOwner)(this).lookup('service:fastboot');
    }
    bestLanguage(languages) {
      const userLanguages = this.fastboot?.isFastBoot || false ? this.fastbootAdapter.fetchLanguages() : this.browserAdapter.fetchLanguages();
      const userLangaguesWithBaseLanguage = userLanguages.map(userLanguage => {
        return {
          ...userLanguage,
          baseLanguage: this.getBaseLanguage(userLanguage.language)
        };
      });
      const supportedUserLanguages = this.intersectLanguages(userLangaguesWithBaseLanguage, languages);
      const sortedLanguages = this.sortLanguagesByScore(supportedUserLanguages);
      return sortedLanguages[0] ? this.mapToLanguage(sortedLanguages[0]) : null;
    }
    bestLanguageOrFirst(languages) {
      const bestLanguage = this.bestLanguage(languages);
      if (bestLanguage) return bestLanguage;
      return {
        baseLanguage: this.getBaseLanguage(languages[0]),
        language: languages[0],
        score: 0
      };
    }
    getBaseLanguage(language) {
      return language.replace(/[\-_].+$/, '');
    }
    intersectLanguages(userLanguages, languages) {
      return userLanguages.reduce((memo, userLanguage) => {
        const matchesLanguage = languages.find(language => language.toLowerCase() === userLanguage.language.toLowerCase());
        if (matchesLanguage) {
          return [...memo, {
            ...userLanguage,
            matches: matchesLanguage
          }];
        }
        const matchesBaseLanguage = languages.find(language => {
          return this.getBaseLanguage(language).toLowerCase() === userLanguage.baseLanguage.toLowerCase();
        });
        if (matchesBaseLanguage) {
          return [...memo, {
            ...userLanguage,
            matches: matchesBaseLanguage
          }];
        }
        return memo;
      }, []);
    }
    sortLanguagesByScore(languages) {
      return languages.sort(({
        score: scoreA
      }, {
        score: scoreB
      }) => {
        return scoreB - scoreA;
      });
    }
    mapToLanguage(language) {
      return {
        baseLanguage: language.baseLanguage,
        language: language.matches,
        score: language.score
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fastbootAdapter", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "browserAdapter", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});