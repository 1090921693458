define("ember-modal-dialog/templates/components/liquid-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isOverlaySibling}}
    <LiquidWormhole
      @stack={{this.stack}}
      @value={{this.value}}
      @class={{concat
        'liquid-dialog-container '
        this.wrapperClassNamesString
        ' '
        this.wrapperClass
      }}
    >
      <div class='{{this.wrapperClassNamesString}} {{this.wrapperClass}}'>
        {{#if this.hasOverlay}}
          <div
            class={{this.overlayClassNamesString}}
            onclick={{action this.onClickOverlay}}
            tabindex='-1'
            data-emd-overlay
          >
          </div>
        {{/if}}
        <div class={{this.containerClassNamesString}} ...attributes>
          {{yield}}
        </div>
      </div>
    </LiquidWormhole>
  {{else}}
    <LiquidWormhole
      @stack={{this.stack}}
      @value={{this.value}}
      @class={{concat
        'liquid-dialog-container '
        this.wrapperClassNamesString
        ' '
        this.wrapperClass
      }}
    >
      {{#if this.hasOverlay}}
        <div
          class={{this.overlayClassNamesString}}
          onclick={{action (ignore-children this.onClickOverlay)}}
          tabindex='-1'
          data-emd-overlay
        >
          <div class={{this.containerClassNamesString}} ...attributes>
            {{yield}}
          </div>
        </div>
      {{else}}
        <div class={{this.containerClassNamesString}} ...attributes>
          {{yield}}
        </div>
      {{/if}}
    </LiquidWormhole>
  {{/if}}
  */
  {
    "id": "Tay1Dr4p",
    "block": "[[[41,[30,0,[\"isOverlaySibling\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@stack\",\"@value\",\"@class\"],[[30,0,[\"stack\"]],[30,0,[\"value\"]],[28,[37,2],[\"liquid-dialog-container \",[30,0,[\"wrapperClassNamesString\"]],\" \",[30,0,[\"wrapperClass\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[15,0,[29,[[30,0,[\"wrapperClassNamesString\"]],\" \",[30,0,[\"wrapperClass\"]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"hasOverlay\"]],[[[1,\"        \"],[10,0],[15,0,[30,0,[\"overlayClassNamesString\"]]],[15,\"onclick\",[28,[37,3],[[30,0],[30,0,[\"onClickOverlay\"]]],null]],[14,\"tabindex\",\"-1\"],[14,\"data-emd-overlay\",\"\"],[12],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[11,0],[16,0,[30,0,[\"containerClassNamesString\"]]],[17,1],[12],[1,\"\\n        \"],[18,2,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,1],null,[[\"@stack\",\"@value\",\"@class\"],[[30,0,[\"stack\"]],[30,0,[\"value\"]],[28,[37,2],[\"liquid-dialog-container \",[30,0,[\"wrapperClassNamesString\"]],\" \",[30,0,[\"wrapperClass\"]]],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"hasOverlay\"]],[[[1,\"      \"],[10,0],[15,0,[30,0,[\"overlayClassNamesString\"]]],[15,\"onclick\",[28,[37,3],[[30,0],[28,[37,5],[[30,0,[\"onClickOverlay\"]]],null]],null]],[14,\"tabindex\",\"-1\"],[14,\"data-emd-overlay\",\"\"],[12],[1,\"\\n        \"],[11,0],[16,0,[30,0,[\"containerClassNamesString\"]]],[17,1],[12],[1,\"\\n          \"],[18,2,null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[11,0],[16,0,[30,0,[\"containerClassNamesString\"]]],[17,1],[12],[1,\"\\n        \"],[18,2,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]]]],[\"&attrs\",\"&default\"],false,[\"if\",\"liquid-wormhole\",\"concat\",\"action\",\"yield\",\"ignore-children\"]]",
    "moduleName": "ember-modal-dialog/templates/components/liquid-dialog.hbs",
    "isStrictMode": false
  });
});