define("ember-cli-swiper/templates/components/swiper-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="swiper-wrapper">
    {{yield (hash slide=(component "swiper-slide"))}}
  </div>
  
  {{#if this.hasPagination}}
    <div class="swiper-pagination"></div>
  {{/if}}
  
  {{#if this.hasNavigation}}
    <div class={{this.nextElClass}}></div>
    <div class={{this.prevElClass}}></div>
  {{/if}}
  
  */
  {
    "id": "Bqv5CdK+",
    "block": "[[[10,0],[14,0,\"swiper-wrapper\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,1],null,[[\"slide\"],[[50,\"swiper-slide\",0,null,null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"hasPagination\"]],[[[1,\"  \"],[10,0],[14,0,\"swiper-pagination\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"hasNavigation\"]],[[[1,\"  \"],[10,0],[15,0,[30,0,[\"nextElClass\"]]],[12],[13],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"prevElClass\"]]],[12],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"if\"]]",
    "moduleName": "ember-cli-swiper/templates/components/swiper-container.hbs",
    "isStrictMode": false
  });
});