define("ember-best-language/services/best-language/browser", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BrowserAdapter extends _service.default {
    fetchLanguages() {
      const browserLanguages = [...(navigator.languages || []), navigator.language, navigator.userLanguage];
      const languages = browserLanguages.filter((language, index, array) => {
        return !!language && array.indexOf(language) === index;
      });
      return languages.map((language, index, array) => ({
        language,
        score: this.computeScore(index, array.length)
      }));
    }
    computeScore(index, total) {
      const scoreDefaultDivider = 10;
      const score = total <= scoreDefaultDivider ? 1 - index / scoreDefaultDivider : 1 - index / total;
      return parseFloat(score.toFixed(2));
    }
  }
  _exports.default = BrowserAdapter;
});