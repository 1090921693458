define("ember-best-language/services/best-language/fastboot", ["exports", "@ember/application", "@ember/service"], function (_exports, _application, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FastBootAdapter extends _service.default {
    get fastboot() {
      return (0, _application.getOwner)(this).lookup('service:fastboot');
    }
    fetchLanguages() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const headers = this.fastboot.request.headers;
      return this.parseHeader(headers.get('Accept-Language') || '');
    }
    parseHeader(header) {
      return header.split(',').map(headerItem => this.parseHeaderItem(headerItem));
    }
    parseHeaderItem(item) {
      const [language, scoreString = 'q=1.0'] = item.split(';');
      const score = parseFloat(scoreString.split('=')[1]) || 0;
      return {
        language,
        score
      };
    }
  }
  _exports.default = FastBootAdapter;
});